/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Profile from './Profile';
import Header from './Header';
import Education from './Education';
import Skills from './Skills';
import Work from './Work';
import Project from './Project';
import Honor from './Honor';
import Contact from './Contact';
import Footer2 from './Footer2';

import {
  Profile0DataSource,
  Header0DataSource,
  Education0DataSource,
  Skills0DataSource,
  Work0DataSource,
  Project0DataSource,
  Honor0DataSource,
  Contact0DataSource,
  Footer20DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Profile
        id="Profile_0"
        key="Profile_0"
        dataSource={Profile0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Header
        id="Header_0"
        key="Header_0"
        dataSource={Header0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Education
        id="Education_0"
        key="Education_0"
        dataSource={Education0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Skills
        id="Skills_0"
        key="Skills_0"
        dataSource={Skills0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Work
        id="Work_0"
        key="Work_0"
        dataSource={Work0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Project
        id="Project_0"
        key="Project_0"
        dataSource={Project0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Honor
        id="Honor_0"
        key="Honor_0"
        dataSource={Honor0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Contact
        id="Contact_0"
        key="Contact_0"
        dataSource={Contact0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer2
        id="Footer2_0"
        key="Footer2_0"
        dataSource={Footer20DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
